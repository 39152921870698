import React, { useEffect, useState } from "react";

import "../Styled/customBotMessage.scss";

import { formatTime, ScrollToBottom } from "./utils";
import { imageSrc } from "../../baseUrl";

const CustomBotMessage = (props) => {
  const [load, setload] = useState(true);
  const [time, setTime] = useState("");
  const [botMsg, setBotMsg] = useState("");
  const [botmsgArray, setBotMsgArray] = useState([]);
  const [msgState, setMessageState] = useState(1);
  const [botContainerClass, setBotContainerClass] = useState('')

  useEffect(() => {
    
    setTimeout(() => {
      setTime(formatTime());
      setload(false);
      // const s = props.message.split(`<a href="/">Read more</a>`)
      const s = [];
      if (s.length) {
        setBotMsg(s[0]);
        setBotMsgArray(s);
      }
     props?.message?.props?.botContainerClass && setBotContainerClass(props?.message?.props?.botContainerClass)
     ScrollToBottom()
    }, 750);

    ScrollToBottom()
  }, []);

  const onReadMore = (e) => {
    setMessageState((prevmsg) => ++prevmsg);
    e.preventDefault();
    // const s = bot_message.split(`<a href="/">Read more</a>`)
    let msg = "";
    for (let index = 0; index < msgState + 1; index++) {
      msg = `${msg} ${botmsgArray[index]}`;
    }
    // console.log(botmsgArray[msgState])
    setBotMsg(msg);
    // setBotMsgArray(pre)

    // console.log(msgState)
  };

  const onReadLess = (e) => {
    setMessageState((prevmsg) => --prevmsg);
    e.preventDefault();
    let msg = "";
    for (let index = 0; index < msgState - 1; index++) {
      msg = `${msg} ${botmsgArray[index]}`;
    }
    setBotMsg(msg);
  };
  return (
    <>
    {/* {console.log(props.message)} */}
      <div className="react-chatbot-kit-chat-bot-avatar">
        <div className="react-chatbot-kit-chat-bot-avatar-container">
          <img
            className="react-chatbot-kit-chat-bot-avatar-container-img"
            src={`${imageSrc}/icons/header-logo.svg`}
            alt="bot message logo"
          />
        </div>
      </div>
      
      
      {load ? 
        (<div className={`react-chatbot-kit-chat-bot-message bot-message ${botContainerClass}`}>
        {props.loader}
      </div>) : props.message ? <div className={`react-chatbot-kit-chat-bot-message bot-message ${botContainerClass}`}>
        <span>
         
            <>
              {botMsg ? (
                <div>
                  <span dangerouslySetInnerHTML={{ __html: botMsg }}></span>
                  {botmsgArray.length && (
                    <div>
                      {msgState < botmsgArray.length && (
                        <a href="/" onClick={(e) => onReadMore(e)}>
                          Read More
                        </a>
                      )}
                      {msgState > 1 && (
                        <a href="/" onClick={(e) => onReadLess(e)}>
                          Read Less
                        </a>
                      )}
                    </div>
                  )}
                </div>
              ) : typeof props.message === "string" ? (
                <div>
                  <span
                    dangerouslySetInnerHTML={{ __html: props.message }}
                  ></span>
                </div>
              ) : (
                props.message
              )}
            </>
          
        </span>
        <div className={`react-chatbot-kit-chat-bot-message-arrow ${botContainerClass && "border"+botContainerClass}`}></div>
        {!load && <span className="timestamp"> {time} </span>}
      </div> : <></>
      }
    </>
  );
};

export default CustomBotMessage;
