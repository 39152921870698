import React from 'react'
import ReactDOM from "react-dom"
import "../Styled/BranchAddress.scss";
import { imageSrc } from "../../baseUrl";
import Context from "../Context/Context";
import { useState } from 'react';
import { useEffect, useContext } from 'react';

const BranchAddressComponent = ({ address, maps_iframe_link, maps_pin }) => {
    const {showMap, setShowMap} =  useContext(Context)
    const addressArray = address.split(",");
    const addressHeading = addressArray[0];
    addressArray.shift();
    // console.log(maps_iframe_link)
    const closeMap = () =>{
        setShowMap(false)
    }

    return (
      <div className="branchContainer-content-address">
      <div className="branchContainer-content-address-text">
        <span className="branchContainer-content-address-text-heading"> 
          <img className="inline-img" src={`${imageSrc}/icons/location.svg`} alt="location" />
          <strong>{addressHeading}</strong>
        </span>
        <span>
          {addressArray.map((e,id)=> id < addressArray.length-1 ? e+',': e)}
        </span>
        </div>
       {maps_iframe_link && <span className="branchContainer-content-address-map">
          <span className="branchContainer-content-address-map-btn" onClick={()=>setShowMap(true)}>
            Open in Maps
          </span>
        </span>}
        {showMap && <RenderIframe maps_iframe_link={maps_iframe_link} closeMap={closeMap} maps_pin={maps_pin}/>}
      </div>
    );
  };

const RenderIframe = ({maps_iframe_link, closeMap, maps_pin}) =>{
    console.log(maps_iframe_link)
    const [onFullScreen, setOnFullScreen] = useState(false)
    const [mapLoaded, setMapLoaded] = useState(false)
    useEffect(()=>{
        document.getElementById('branchIframe').onload = () =>{
            // console.log('iframe loaded')
            setMapLoaded(true)
        }
    },[])
    
    const makeFullScreen = ()=>{
       const elem = document.getElementById('branchLocation')
       elem.requestFullscreen()
       setOnFullScreen(true);
    }
    const exitFullScreen = () =>{
        document.exitFullscreen();
       setOnFullScreen(false)
    }
    const openMap = (maps_pin) =>{
      window.open(maps_pin, '_blank');
      closeMap()
  }
    return ReactDOM.createPortal(
      <div id="branchLocation" className="branchContainer-content-address-mapContainer">
        {!mapLoaded && <span className="branchContainer-content-address-mapContainer-loader">Please wait...</span>}
        <span className="branchContainer-content-address-mapContainer-icons">
          <i class="fa-solid fa-circle-xmark" title='close' onClick={closeMap}></i>
          {!onFullScreen ? (
            <i className="fa-solid fa-expand" title='full screen' onClick={makeFullScreen}></i>
          ) : (
            <i class="fa-solid fa-minimize" title='minimize' onClick={exitFullScreen}></i>
          )}
          <i class="fa-solid fa-location-dot" title='Open in Maps App' onClick={() => openMap(maps_pin)}></i>
        </span>
        <iframe
        id="branchIframe"
          className="branchContainer-content-address-mapContainer-iframe"
          title="test"
          src={maps_iframe_link}
          frameborder="0"
          allowfullscreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          style={{ border: 0 }}
          allowFullScreen
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>,
      document.querySelector(".react-chatbot-kit-chat-message-container")
    );
}

const BranchItemComponent = ({item}) => {
            return (
                <div className='branchContainer'>{
                    "branch_address" in item ?
                        <BranchAddressComponent address={item.branch_address} maps_iframe_link={item.g_maps_iframe} maps_pin={item.g_maps_link}/>
                    : "phone_number" in item ?
                    <div className='branchContainer-content-phone'>
                        <span className='branchContainer-content-phone-header'>{item.branch_name}<br/></span>
                        <div className='branchContainer-content-phone-content'>
                            <img className="inline-img" src={`${imageSrc}/icons/phone-call.svg`} alt="location"/>
                            <span className='branchContainer-content-phone-content-text'><strong>Call Us:</strong><a href={`tel:${item.phone_number}`}>{item.phone_number}</a> </span>
                        </div>
                    </div>
                    : 
                    <div className='branchContainer-content-email'>
                        <span className='branchContainer-content-email-header'>{item.branch_name}<br/></span>
                        <div className='branchContainer-content-email-content'>
                        <img className="inline-img" src={`${imageSrc}/icons/mail.svg`} alt="location"/>
                        <span className='branchContainer-content-email-content-text'><strong>Email:</strong> <a href={`mailto: ${item.email_address}`}>{item.email_address}</a></span>
                        </div>
                    </div>
                    
                    }
                </div>
    
        )
    }
const BranchAddress = (props) => {
    console.log(props)
    const alldataitmes = [...props.data]
    const lastMessageOptions = alldataitmes[alldataitmes.length-1]
    alldataitmes.pop()
    
  const branchHandler = (item) => {
    props.addClientMessage(item.branch_name + " - " + props.header)
    props.addPlaneMessage(<BranchItemComponent item={item} botContainerClass={'branchInfo'} />)
    props.addlastOptionsChatBotMessage(lastMessageOptions.bot_message, lastMessageOptions)
  }
  useEffect(()=>{
    if(alldataitmes.length === 1){
        props.addlastOptionsChatBotMessage(lastMessageOptions.bot_message, lastMessageOptions)
    }
  }, [])
  return (
    alldataitmes.length > 1 ? <div className="branch-address">
        <span className="branch-address-header" >Check {props.header} for the branch<br/></span>
        <div className="branch-address-container">
        {alldataitmes.map((item, idx) => 
            <div key={idx}>
                <div 
                className="branch-address-container-uniq" 
                onClick={() => branchHandler(item)}>
                    <span >{item.branch_name}</span>
                </div>
            
            </div>
            
            )}
        </div>
    </div> : <BranchItemComponent item={alldataitmes[0]} botContainerClass={'branchInfo'} />
  )
}

export default BranchAddress