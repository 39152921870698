import React, { useEffect, useState } from "react";
import "../Styled/banner.scss";
import { formatTime, getHostName } from "./utils";
import { imageSrc } from "../../baseUrl";
import { GetActiveBannerOption, GetAllOptionForMessageInput } from "../apiCall";

const Banner = (props) => {
  const [time, setTime] = useState("");

  // const bannerOption = [
  //     {name: "", uuid: ""},
  //     {name: "Registry", uuid: ""},
  //     {name: "Public Issues", uuid: ""},
  //     {name: "Rights Issues", uuid: ""},
  //     {name: "Bond Servicing", uuid: ""},
  //     {name: "Depository Services", uuid: ""},

  // ]
  const [data, setData] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [description, setDescription] = useState();

  useEffect(() => {
    setTime(formatTime());
    GetActiveBannerOption().then((data) => {
      // console.log(data)
      const descripton = data[data.length -1]
      setDescription(descripton)
      setData(data.slice(0, data.length-1));
     
    });
  }, []);

  const handleBannerOnClick = (data) => {
    const requestData = {
      session_id: localStorage.getItem("chatbot-sessionId"),
      selected_context: data.uuid,
      typed_msg: "",
      host: getHostName(),
    };
    props.addClientMessage(data.name);
    props.handleMainOption(null, requestData);
  };

  const handleNext = () => {
    setListLength((prevstate) => prevstate + 6);
    document.getElementById("inputCon").focus();
  };
  const handlePrev = () => {
    setListLength((prevstate) => prevstate - 6);
    ;document.getElementById("inputCon").focus();
  };
  return (
    <div className="banner">
      <div className="banner-container">
        <div className="banner-container-left">
          <img src={`${imageSrc}/icons/bannerImg.svg`} alt="banner img" />
        </div>
        <div className="banner-container-right">
          <div className="banner-container-right-right">
            <div className="banner-container-right-top">
              <span className="banner-container-right-top-withIcon">
                Hi there! I am{" "}
                <img
                  className="banner-container-right-top-withFont"
                  src={`${imageSrc}/icons/banner-logo-blue.svg`}
                  alt="banner logo"
                />
              </span>
              <span>I can help you with your queries regarding</span>
            </div>
            <div className="banner-container-right-right-top">
              {data.map((e, el) => {
                if (el >= listLength && el < listLength + 6) {
                  return e.status === "active" ? (
                    <span key={el}
                      className="banner-container-right-right-top-active"
                      onClick={() => handleBannerOnClick(e)}
                    >
                      <img
                        src={`${imageSrc}/icons/bannerArrow.svg`}
                        alt="arwt"
                      />
                      {e.name}
                    </span>
                  ) : (
                    <span key={el}>
                      <img
                        src={`${imageSrc}/icons/bannerArrow.svg`}
                        alt="arwt"
                      />
                      {e.name}
                    </span>
                  );
                }
              })}
            </div>
            <div className="banner-container-right-right-mid">
              {!(listLength + 6 >= data.length) ? (
                <span onClick={handleNext} title="Next">
                  <i className="fa-solid fa-angles-right"></i>
                </span>
              ) : (
                <span onClick={handlePrev} title="Previous">
                  <i className="fa-solid fa-angles-left"></i>
                </span>
              )}
            </div>
            <div className="banner-container-right-right-bottom">
              Get started by selecting a query from below or type your own
              query.
              <span
                title="Disclaimer"
                onClick={() => handleBannerOnClick(description)}
                className="banner-container-right-right-bottom-des"
              >
                i
              </span>
            </div>
          </div>
        </div>
      </div>
      <span className="banner-timestamp">{time}</span>
    </div>
  );
};

export default Banner;
