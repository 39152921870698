import React from 'react'
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react'
import { GetSearchCompney, GetTypedCompney } from '../../../apiCall';
import "../../../Styled/Forms/isin.scss";
import { getHostName } from '../../utils';

const Isin = ({msg, addPlaneMessage, Rated, changeIsRated,input_required,data,handleMainOption}) => {
    const [isin, setIsin] = useState("")
    const [type, setType] = useState("ISIN")
    const [suggestion, setSuggestions] = useState([]);
    const [displaySuggestion, setDisplaySuggestion] = useState(false)
    const [submited, setSubmitted] = useState(false);

    useEffect(()=>{
        console.log(data)
        const Element = document.querySelector(".Container-ChatbotContainer")
        const checkEvent = (e) => {
          // console.log(e);
         if(e.target.className === "isin-form-input" && suggestion.length){
            setDisplaySuggestion(true)
         }else{
            setTimeout(() => {
                setDisplaySuggestion(false);
            }, 100); 
         }
          
        };
        // console.log("test");
        if (!submited) {
          Element.addEventListener("click", checkEvent);
        }
        return () => {
          Element.removeEventListener("click", checkEvent);
        };

    },[submited, suggestion])
    
    const HandleSearchedCompney = (value) =>{
            GetSearchCompney(value).then((data)=>{
                
                if (data.length == 0){
                  const new_data = {
                    client: "No Results Found",
                    id: 0, 
                  };
                  data = [new_data]
                }
                setSuggestions(data)
                // console.log(data)
                setDisplaySuggestion(true)
            })
    }

    const debounce = (func) => {
        let IsinSearchTimmer;
        return function (...args) {
          const context = this;
          if (IsinSearchTimmer) clearTimeout(IsinSearchTimmer);
          IsinSearchTimmer = setTimeout(() => {
            IsinSearchTimmer = null;
            func.apply(context, args);
          }, 500);
        };
      };
    const optimizedFn = useCallback(debounce(HandleSearchedCompney), [])

    const onchange = (e)=>{
        setIsin(e.target.value)
        // const shouldnotInclude = ["pvt", "ltd", "private", "limited"]
        // console.log(e.target.value.includes(shouldnotInclude))
        const val = e.target.value.trim()
        if(e.target.value.length >= 3 && !(val === "pvt" || val === "ltd")){
            optimizedFn(e.target.value)
        }else if(!suggestion.length || e.target.value.length < 3) {
            displaySuggestion && setDisplaySuggestion(false)
        }
    }   

    const onSuggestionClick = (item) =>{
        // console.log(item)
        setIsin(item.client)
        setDisplaySuggestion(false)
    }

    const handleSubmit = (e)=>{
        e.preventDefault()
        setSubmitted(true)

       const requestData =  {
        client: isin, 
        search_by:type
      }

        GetTypedCompney(requestData)
        .then((data)=>addPlaneMessage(data))
        .catch((e)=>addPlaneMessage(`Sorry we are not able to find "<strong>${isin}</strong>" company.`))
    }

    const handleSearch = ()=>{
      const requestData = {
        session_id: localStorage.getItem("chatbot-sessionId"),
        selected_context: data.selected_context,
        typed_msg: "",
        host: getHostName(),
      };

    handleMainOption(null, requestData)

      
    }
  return (
    <div className='isin'>
      <span dangerouslySetInnerHTML={{__html: msg}}></span>
     
      <form className='isin-form' onSubmit={handleSubmit}>
        {(displaySuggestion && suggestion.length !==0  ) && 
            <div className='isin-form-suggestion'>
                {suggestion.map((e,el)=>{
                    if (e.id > 0){
                      return (<span key={e.id} onClick={()=>onSuggestionClick(e)} className='isin-form-suggestion-item'>{e.client}</span>)
                    }else{
                      return (<span key={e.id} className='isin-form-suggestion-item-error'>{e.client}</span>)
                    }
                })}
            </div>}
          
         <input  className='isin-form-input' type="text" required={true} value={isin} disabled={submited} onChange={onchange} placeholder="Search Company."/>
         <span className='isin-form-select'>Find By: <select disabled={submited} onChange={(e)=>setType(e.target.value)}>
          <option>ISIN</option>
          <option>CIN</option>
          <option>ISIN + CIN</option>
         </select></span>
         <button className='isin-form-btn' disabled={(isin.length < 10 || submited) }>Search</button> 
      </form>
      <span className='isin-reserch' title='refresh' >{submited &&  <i onClick={handleSearch} class="fa-solid fa-arrows-rotate"></i>}</span>
    </div>
  )
}

export default Isin