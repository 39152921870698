import React, { useState, useEffect, useRef } from "react";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config from "./config";
import MessageParser from "./MessageParser";
import ActionProvider from "./ActionProvider";
import { FaMinus } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { AiFillRobot } from "react-icons/ai";
import {imageSrc} from "../../baseUrl"

// import { mobile, tab, desktopQuater, desktopHalf } from "./../../responsive";
// import styled from "styled-components";

import "../Styled/ChatBotMain.scss"
import { ContextProvider } from "../Context/Context";
import { getToken } from "../apiCall";

let timmer;
const ChatBotMain = () => {
  const [minimize, setMinimize] = useState(false);
  const [close, setClose] = useState(true)
  const [tooltip, setTooltip] = useState(false);
  const [isRated, setIsRated] = useState(false);
  const [untaggedCount, setuntaggedCount] = useState(0);
  const [showisbot, setShowIsBot] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  const minimizeHandler = () => {
    // console.log("minizimed")
    document.querySelector(".Container").focus()
    setMinimize(true);
    
  //   timmer = setTimeout(() => {
  //     setTooltip(true)
  // }, 1 * 60 * 1000);
  
    // setTooltip(true)
  };

  const maximizeHandler = () =>{
    setMinimize(false)
    // setTooltip(false)
  }

  const openHandler = () => {
    // setTooltip(false)
    const token = localStorage.getItem("chatbot_token");
    if (token) {
      setClose(false);
    } else {
      getToken()
        .then(() => {
          setClose(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const closehandler = () =>{
    // console.log("in Close Handler")
    localStorage.removeItem("chatbot-sessionId")
    // clearTimeout(timmer)
    // setTooltip(false)
    setuntaggedCount(0)
    setClose(true)
    setIsRated(false)
    setShowIsBot(false)
    setShowPopup(null)
    
  }

  useEffect(()=>{
   
    return ()=>{
      // clearTimeout(timmer)
    }
   
  }, [])
 

  return (
    <div className="Container">
     
        { !close ?
         <>
        <div className={!minimize ? "Container-ChatbotContainer" : "Container-ChatbotContainer Container-ChatbotContainer-close"}>
          <div>
            <ContextProvider value={{isRated,showPopup, setShowPopup, setIsRated, untaggedCount, setuntaggedCount, showisbot, setShowIsBot, showMap, setShowMap}}>
            <Chatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
            </ContextProvider>
            <div className="Container-ChatbotContainer-CloseTags">
              
                <div className="IconHandler" title="Minimize" onClick={minimizeHandler}>
                    <img src={`${imageSrc}/icons/h_minimize_btn.svg`} alt="menu"/>
                    {/* <FaMinus title="Minimize" /> */}
                </div>
                {/* <div className="IconHandler" title="Close" onClick={closehandler}>
                  <ImCross />
                </div> */}
            </div>
            
          </div>
        </div>
       
         <div  className={minimize?  "IconHandler-open": "IconHandler-close"} color={"#2983c5"}>
       {tooltip && <div className="Container-minimized" >
         <span className="Container-minimized-text" ><span onClick={maximizeHandler}>Hi! I am IDia, how can I help you?</span><span className="Container-minimized-text-close" onClick={()=> setTooltip(false)}><span>+</span></span></span><span className="Container-minimized-text-arrow"></span>
         </div>}
         <div onClick={maximizeHandler} className="Container-ChatbotIcon">
            <img src={`${imageSrc}/icons/main-logo.png`} alt="chat bot icon"/>
            </div>
        </div>
        </> :
        
        
    
        
        <div className={close?  "IconHandler-open": "IconHandler-close"} color={"#2983c5"}>
         
          <div onClick={openHandler} className="Container-ChatbotIcon">
            <img src={`${imageSrc}/icons/main-logo.png`} alt="chat bot icon"/>
            {/* <span>IDia</span> */}
            </div>
        </div>}
       
      
    </div>
  );
};

export default ChatBotMain;
// "react-chatbot-kit": "git+https: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:x-oauth-basic@github.com/bluetickconsultants/chatbot-ui.git",