import { getHostName } from "./utils";

class MessageParser {
  constructor(actionProvider, state) {
    this.actionProvider = actionProvider;
    this.state = state;
  }

  parse(message) {
    const lowerCase = message.toLowerCase();

    if (lowerCase.trim() === "hi") {
      this.actionProvider.handleGenericMessages("Hi there! How can I help you?");
    }
    else if (lowerCase.trim() === "hello") {
      this.actionProvider.handleGenericMessages("Hello there!!");
    }
    else if (lowerCase.trim() === "who are you" || lowerCase.trim() === "who r u" 
    || lowerCase.trim() === "idia" || lowerCase.trim() === "i-dia" || lowerCase.trim() === "idia meaning" || lowerCase.trim() === "what does idia mean"
    || lowerCase.trim() === "who are u" || lowerCase.trim() === "what is ur name" || lowerCase.trim() === "what is your name") {
      this.actionProvider.handleGenericMessages("I am IDIA. Your E-Assistant. I'd love to help you in any queries you have ");
    }

    else if (lowerCase.trim() === "who am i") {
      this.actionProvider.handleGenericMessages("Welcome User");
    }

    else if (lowerCase.trim() === "thanks" || lowerCase.trim() === "thank you" || lowerCase.trim() === "thank u") {
      this.actionProvider.handleGenericMessages("My Pleasure!");
    }
    else if (lowerCase.trim() === "great" || lowerCase.trim() === "feels great" || lowerCase.trim() === "good") {
      this.actionProvider.handleGenericMessages("Awesome!");
    }
    else if (lowerCase.trim() === "awesome") {
      this.actionProvider.handleGenericMessages("That's great. Can I help you with anything else?");
    }
    // else if (lowerCase.trim() === "bye") {
    //   this.actionProvider.handleGenericMessages("I am here if you still need me. Chat soon !!");
    // }
    else if (lowerCase.trim() === "see you") {
      this.actionProvider.handleGenericMessages("Bye ! It was great chatting to you");
    }
    else if (lowerCase.trim() === "love") {
      this.actionProvider.handleGenericMessages("Not really, but we can certainly Chat!");
    }
    else if (lowerCase.trim() === "do you get smart" || lowerCase.trim() === "do you get smarter") {
      this.actionProvider.handleGenericMessages("Every single day.");
    }
    else if (lowerCase.trim() === "you are funny" || lowerCase.trim() === "u r funny") {
      this.actionProvider.handleGenericMessages("Thanks, you are too kind");
    }
    else if (lowerCase.trim() === "are you a real person" || lowerCase.trim() === "r u a real person"
     || lowerCase.trim() === "r u real person" || lowerCase.trim() === "are you real person") {
      this.actionProvider.handleGenericMessages("Real - Yes!! Person - No ");
     }
     else if (lowerCase.trim() === "are you human" || lowerCase.trim() === "r u human") {
      this.actionProvider.handleGenericMessages("No, but I have the deepest respect for humans. You invented calculus. And milkshakes");
    }
    else if (lowerCase.trim() === "good morning" || lowerCase.trim() === "good noon"
    || lowerCase.trim() === "good afternoon" || lowerCase.trim() === "good evening" || lowerCase.trim() === "good morrow") {
      this.actionProvider.handleGenericMessages("Greetings to you too !!");
    }
    // else if (lowerCase.trim() === "good night" || lowerCase.trim() === "good n8") {
    //   this.actionProvider.handleGenericMessages("Talk To You Tomorrow");
    // }
    else if (lowerCase.trim() === "good day") {
      this.actionProvider.handleGenericMessages("Good Day to you too !!");
    }

    else if(lowerCase.trim()){
      const requestData = {
        session_id: localStorage.getItem("chatbot-sessionId"),
        selected_context:  "",
        typed_msg: lowerCase,
        host: getHostName(),
      }
      this.actionProvider.handleMainOption(lowerCase,requestData)
    }
   
  }
}


export default MessageParser;
