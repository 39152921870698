import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom"
import { imageSrc } from "../../../baseUrl";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "../../Styled/RenderPdf.scss";
import { highlightPlugin, MessageIcon } from "@react-pdf-viewer/highlight";
import { zoomPlugin, RenderCurrentScaleProps  } from '@react-pdf-viewer/zoom';
import { AiFillCloseCircle,AiOutlineExport} from "react-icons/ai"
import { Viewer, Worker, Button,  Position, Tooltip } from "@react-pdf-viewer/core";;


const RenderPdf = ({pdf, previewPDF, setPreviewPDF}) => {
  const [notes, setNotes] = useState([]);
  const [scale, setScale] = useState(0);

  let noteId = notes.length;
  useEffect(()=>{
    const inputcontainer = document.getElementById("inputCon")
    const ClosePdfOnTypeIng = (e)=>{
      setPreviewPDF(false)
      // console.log(e)
      inputcontainer.removeEventListener("input",ClosePdfOnTypeIng)
    }
      inputcontainer.addEventListener('input',ClosePdfOnTypeIng)
    
  }, [])
 

  const renderHighlightTarget = (props) => {
    const highlightContent = () => {
      const note = {
        id: ++noteId,
        highlightAreas: props.highlightAreas,
        quote: props.selectedText
      };
      setNotes([...notes, note]);
      props.toggle();
    };
    return (
      <div
        style={{
          background: "#eee",
          display: "flex",
          position: "absolute",
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          transform: "translate(0, 8px)"
        }}
      >
        <Tooltip
          position={Position.TopCenter}
          target={
            <span className="main-btn" onClick={highlightContent}>
              <MessageIcon />
            </span>
          }
          content={() => <div style={{ width: "100px" }}>Highlight</div>}
          offset={{ left: 0, top: -8 }}
        />
      </div>
    );
  };

  const renderHighlights = (props) => (
    <div>
      {notes.map((note) => (
        <React.Fragment key={note.id}>
          {note.highlightAreas
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                style={Object.assign(
                  {},
                  {
                    background: "yellow",
                    opacity: 0.4
                  },
                  props.getCssProperties(area, props.rotation)
                )}
              />
            ))}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlights
  });

  const zoomPluginInstance = zoomPlugin();
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

  // const zoomPluginInstance = zoomPlugin(props?: ZoomPluginProps);
  
  return ReactDOM.createPortal(
    <div className="model">
      {/* {console.log(previewPDF)} */}
    {previewPDF && (
      <div className="border">
        
        <div className="zoom">
        <div className="zoom-in" >
              <ZoomIn>
                  {(props) => (
                      <span title="Zoom In"
                          
                          onClick={() => {props.onClick()}}
                      >
                          +
                      </span>
                  )}
              </ZoomIn>
          </div>
          <div style={{ padding: '0px 2px' }}>
                    <CurrentScale>
                    {(props) => <>{setScale(props.scale)}</>}
                        {/* {(props) => {Math.round(props.scale * 100) <= 60 ? setDisableZoomin(true): setDisableZoomin(false)}} */}
                    </CurrentScale>
          </div>
          <div title="Zoom Out" className={`zoom-out ${scale <= 0.6 ? "disable": ""}`}>
              <ZoomOut>
                  {(props) => (
                      <span 
                          onClick={props.onClick}
                      >
                          -
                      </span>
                  )}
              </ZoomOut>
          </div>
          
        </div>
        <div className="closetag" title="Close" onClick={() =>{setPreviewPDF(false);document.getElementById("inputCon").focus()}}>
          <AiFillCloseCircle size={30} />
        </div>
        <div className="download" title="Open in new tab" onClick={() =>{setPreviewPDF(false);document.getElementById("inputCon").focus()}}>
          <a href={pdf} rel="noopener noreferrer" target="_blank"><AiOutlineExport/></a>
        </div>
        <div className="webviewer">
          <Worker workerUrl={`${imageSrc}/pdf.worker.min.js`}>
            <Viewer
              // initialPage={2}
              fileUrl={pdf}
              plugins={[highlightPluginInstance,zoomPluginInstance]} 
            />
          </Worker>
        </div>
      </div>
    )}
  </div>, document.querySelector(".react-chatbot-kit-chat-message-container")
  )
   
};

export default RenderPdf;
