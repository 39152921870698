import React from 'react'
import ThankYou from '../ThankYou'
import Isin from './InputForms/Isin'

const Forms = ({msg, addPlaneMessage, Rated, changeIsRated,input_required, data, handleMainOption}) => {
  console.log(input_required)
  return (
    input_required ? 
      <Isin {...{msg, addPlaneMessage, Rated, changeIsRated,input_required, data, handleMainOption}}/>
     :<ThankYou bot_message={msg}  
               addPlaneMessage={addPlaneMessage} 
               Rated={Rated} 
               changeIsRated={changeIsRated} />
  )
}

export default Forms