import React from "react";
import "../Styled/botAvatar.scss"
import { imageSrc } from '../../baseUrl'

const BotAvatar = () => {
  return (
    <></>
    // <div class="react-chatbot-kit-chat-bot-avatar">
    //   <div class="react-chatbot-kit-chat-bot-avatar-container">
    //       {/* <img src={`${imageSrc}/icons/BOTIcon.svg`} alt="I" /> */}
    //       <span  class="react-chatbot-kit-chat-bot-avatar-container-text">IDia</span>
    //     {/* <p class="react-chatbot-kit-chat-bot-avatar-letter">!</p> */}
    //   </div>
    // </div>
  );
};

export default BotAvatar;
