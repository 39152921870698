import { CallApi } from "../apiCall";
import MessageHandler from "./widgets/MessageHandler";

class ActionProvider {
  constructor(
    createChatBotMessage,
    setStateFunc,
    createClientMessage,
    stateRef,
    createCustomMessage,
    ...rest
  ) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
    this.stateRef = stateRef;
    this.createCustomMessage = createCustomMessage;
  }


  
  handleGenericMessages = (msg) => {
    const message = this.createChatBotMessage(msg);
    this.addMessageToState(message);
  };

  // handling all option of Bot Responses
  handleMainOption = (msg, requestData) => {
    
     
    CallApi(requestData).then((data)=>{
      // console.log("this is from handle main option",data)
      // console.log("check")
      //checking if error is there from api call
      if(data === "error"){
        this.addErrorMessage()
      }else{
        // looping through bot message to add message option at the last message for option like "anything else help"
      data.bot_message.forEach((bot_message,el) => {
        // let msg = null
        //if the bot message has last element then add option the message
        if(el === data.bot_message.length-1){
          let msg;
          if (bot_message ){
            msg = this.createChatBotMessage(
              <MessageHandler
                addPlaneMessage={this.addPlaneMessage}
                handleMainOption={this.handleMainOption}
                data={data}
                msg={bot_message}
                last={true}
                Rated={this.stateRef.isRated}
                changeIsRated={this.changeIsRated}
              />,
              {
                widget: "options",
                data: data
              }
            );
          }
          else {
            msg = this.createChatBotMessage(
              "",
              {
                widget: "options",
                data: data
              }
            );
          }
           
          this.addMessageToState(msg);
        }else{
       //just adding message without option btns
       const  msg = this.createChatBotMessage( <MessageHandler
          addPlaneMessage={this.addPlaneMessage}
          handleMainOption={this.handleMainOption}
          data={data}
          last={false}
          msg={bot_message}
          Rated={this.stateRef.isRated}
          changeIsRated={this.changeIsRated}
        />)
        this.addMessageToState(msg);
        }
       
      });
    }
    })
  };


  addlastOptionsChatBotMessage = (botmessage,data)=>{
    const msg = this.createChatBotMessage(
      botmessage,
      {
        widget: "options",
        data: data
      }
    );
    this.addMessageToState(msg);
  }

  addPlaneChatBotMessage =(ChatMessage)=>{
    const msg = this.createChatBotMessage(ChatMessage)
    this.addMessageToState(msg);
  }

// creating client Message
  addClientMessage = (ClientMessage) => {
    const Cmessage = this.createClientMessage(ClientMessage);
    this.addMessageToState(Cmessage);
  };

//adding plane mssage without component only string
  addPlaneMessage = (res_msg) => {
    // console.log(res_msg);
    const msg = this.createChatBotMessage(res_msg);
    this.addMessageToState(msg);
  };
  changeIsRated = ()=>{
    // document.querySelectorAll(".chatbot-rating-container-others").forEach((e)=>e.style = "pointer-events: none;")
    this.setState((prevState) => ({
      ...prevState,
     isRated: true
    }));
  }

  //adding Error Message
  addErrorMessage = () =>{
    const msg = this.createChatBotMessage(<span className="Error-Message">Sorry, Something Went wrong Please Try Again.</span>);
    this.addMessageToState(msg);
  }

// method to add message to state while keeping old one's
  addMessageToState = (message) => {
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };
};



export default ActionProvider;
