import React, { useState } from "react";
import { useContext } from "react";
import { Rating } from "react-simple-star-rating";
import { CallApiFeedback, CallApiRatings } from "../apiCall";
import Context from "../Context/Context";
import "../Styled/ThankYou.scss"

export default function ThankYou(props) {

  const [rating, setRating] = useState(0); // initial rating value

  const {isRated, setIsRated} = useContext(Context)
  // if(props.Rated){
  //   props.addPlaneMessage("Thank you But you have already Rated.")
  //   return
  // }
  // Catch Rating value
  const handleRating = (rate) => {
    setIsRated(true)
    if(!rating){
      const Rate = rate / 20
    setRating(rate);
    // other logic
    const data = {
      rating: `${Rate}`
    }
      CallApiRatings(data).then((data)=>{
        if(Rate >= 4){
          props.addPlaneMessage(data[0])
        }else{
          props.addPlaneMessage(<FeadBackJSX/>)
        }
        props.changeIsRated()
      })
    }
     
  };

  const FeadBackJSX = () => {
    const [submitted, setSubmitted] = useState(false)
    const [feedback, setFeedback] = useState("")
    const [reason, setReason] = useState("")
    const [feedbackCount, setFeedbackCount] = useState(0);
    const handleSubmitClick = (e)=>{
      e.preventDefault()
        if(reason || feedback.trim()){
          const requestData = {
            reason: reason,
            description: feedback
          }
          CallApiFeedback(requestData).then((data)=>{
            // console.log(data)
            props.addPlaneMessage("Thank you for your feedback.")
            setSubmitted(true)
          })
        }

    }
    const onInputChangeHandler = (e)=>{
      setFeedback(e.target.value)
      setFeedbackCount(e.target.value.length)
    }
    return (
      <div className="feedbackRating">
        <span className="feedbackRating-heading">I can tell you are upset. If my answers were not helpful, you can help us with your valued feedback:</span>
        <form onSubmit={handleSubmitClick} className="feedbackRating-container">

          <select 
              className="feedbackRating-container-select" 
              title="Select a reason" 
              disabled={submitted}
              // value={reason}
              onChange={(event) =>  setReason(event.target.value)}
              required={true}>
            <option style={{display: "none"}} value="0">Select a reason</option>
            <option value="Not satisfied with the response.">Not satisfied with the response.</option>
            <option value="The query was not resolved even partially.">The query was not resolved even partially.</option>
            <option value="Higher response time.">Higher response time.</option>
            <option value="Other">Other</option>

          </select>
      
          <textarea rows="3"
                  placeholder="Feedback"
                  maxLength="250" 
                  value={feedback} 
                  disabled={submitted}
                  onChange={onInputChangeHandler}  />
          <div className="feedbackRating-container-box">      
            
            <div className="feedbackRating-container-btnContainer">
              <button disabled={submitted} className={`feedbackRating-container-btnContainer-${submitted? "disabled" :"btn"}`}>Send</button>
            </div>
            <span className="feedbackRating-container-counter">Max Length: {feedbackCount}/250</span>
          </div>  
        </form>
      </div>
    );
  };

  return (
    <div className={`chatbot-rating-container-${rating ?"rated": "others"}`}>
      {/* {console.log(isRated)} */}
      <p>{props.Rated ? 'Thank you, Your rating has already been recorded.':props.bot_message}</p>
      {!props.Rated &&<Rating
        onClick={handleRating}
        readonly={isRated}
        ratingValue={rating} /* Available Props */
      />}
    </div>
  );
}
