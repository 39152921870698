import React, { useEffect, useState } from "react";
import { formatTime, ScrollToBottom } from "../ChatBot/utils";
import "../Styled/customUserMessage.scss";
import { imageSrc } from '../../baseUrl'
const CustomUserMessage = (props) => {
 const [time, setTime] = useState()
 useEffect(()=>{
     setTime(formatTime())
     ScrollToBottom()
 },[])
//   console.log(props);
  return (

    <div className="react-chatbot-kit-user-chat-message user-message">
      {props.message.replace("-2023 ", "")}
      <div className="react-chatbot-kit-user-chat-message-arrow"></div>
      <span className="timestamp-user"><img src={`${imageSrc}/icons/doubleTick.svg`} alt="seen"/>{time}</span>
    </div>
  );
};

export default CustomUserMessage;
