import React from "react";
import { useState, useContext } from "react";
import { imageSrc } from "../../baseUrl";
import Context from "../Context/Context";
import "../Styled/chatBotheader.scss";
import ThankYou from "./ThankYou";
import BranchAddress from "./BranchAddress";
import { GetBranchAddress } from '../apiCall';
import { getHostName } from "./utils";


const ChatbotHeader = (props) => {
  const [menuSlide, setMenuSlide] = useState(false)
  const {showisbot, setShowIsBot} = useContext(Context)
  const {showMap, setShowMap} =  useContext(Context)
  // useState(()=>{
  //   console.log(showisbot)
  //   if(showisbot){
  //     setTimeout(() => {
  //       setShowIsBot(false)
  //     }, 2000);
  //   }
  // }, [showisbot])
  if(showisbot){
    setTimeout(() => {
      setShowIsBot(false)
    }, 10000);
  }
  const handleClick = () => {
    const message = props.createChatBotMessage(
      `<span class="initial-MessageChat">Welcome to <img class="ida-logo" src=${imageSrc}/icons/banner-logo-blue.svg alt="ida"/> - Your E-Assistant.</span>`,
      {
        widget: "options",
      }
    );

    props.setState({ messages: [message] });
  };

  const RatingsHandler = () =>{
    setShowMap(false);
    props.addPlaneMessage(<ThankYou bot_message={"Please rate your experience"} Rated={props.stateRef.isRated} changeIsRated={props.changeIsRated} addPlaneMessage={props.addPlaneMessage}/>);
  }

  const EmailHandler = () =>{
    setShowMap(false);
    const requestData = "host=" + getHostName() + "&field=email"

    GetBranchAddress(requestData)
        .then((data)=>{
          props.addClientMessage("Email");
          props.addPlaneMessage(<BranchAddress botContainerClass={'branchInfo'}  data={data} header={"Email"} {...props}/>)})
        .catch((e)=>props.addPlaneMessage(`Please try again after sometime`))
  }

  const TelephoneHandler = () =>{
    setShowMap(false);
    const requestData = "host=" + getHostName() + "&field=telephone"

    GetBranchAddress(requestData)
        .then((data)=>{
          props.addClientMessage("Phone Number");
          props.addPlaneMessage(<BranchAddress botContainerClass={'branchInfo'} data={data} header={"Phone Number"} {...props}/>)})
        .catch((e)=>props.addPlaneMessage(`Please try again after sometime`))
  }

  const LocationHandler = () =>{
    setShowMap(false);
    const requestData = "host=" + getHostName() + "&field=address"

    GetBranchAddress(requestData)
        .then((data)=>{
          props.addClientMessage("Location")
          props.addPlaneMessage(<BranchAddress botContainerClass={'branchInfo'} header={"Address"} data={data} {...props}/>)})
        .catch((e)=>props.addPlaneMessage(`Please try again after sometime`))
  }
  // console.log(props)
  return (
    <div className="header"  title="Ask IDia">
      <div className="header-text" onClick={handleClick}>
      <span className="header-light">Ask</span>{" "}
      <img src={`${imageSrc}/icons/header-logo.svg`} alt="header-logo" />
      </div>
      { menuSlide && 
              <>
                <div className="header-menu_close" title="Close Menu" onClick={() => {setShowMap(false);setMenuSlide(!menuSlide)}}>
                    <img src={`${imageSrc}/icons/h_close_menu.svg`} alt="Menu Close"/>
                </div>
                <div className="header-location" title="Location" onClick={LocationHandler}>
                  <img src={`${imageSrc}/icons/h_location_btn.svg`} alt="Location"/>
                </div>
                <div className="header-telephone" title="Telephone" onClick={TelephoneHandler}>
                  <img src={`${imageSrc}/icons/h_telephone_btn.svg`} alt="Telephone"/>
                </div>
                <div className="header-email" title="Mail" onClick={EmailHandler}>
                  <img src={`${imageSrc}/icons/h_email_btn.svg`} alt="Email"/>
                </div>
                <div className="header-rate" title="Rate Us" onClick={RatingsHandler}>
                  <img src={`${imageSrc}/icons/h_rate_btn.svg`} alt="Rate Us"/>
                </div>
              </>
        }
        {!menuSlide && 
          <div className="header-menu" title="Menu" onClick={() => setMenuSlide(!menuSlide)}>
                  <img src={`${imageSrc}/icons/h_menu_btn.svg`} alt="menu"/>
          </div>
        }
      {/* <span className="header-rate" onClick={RatingsClick}><img src={`${imageSrc}/icons/Rating.svg`} alt="Rate" title="Rate Us" /></span> */}
     {showisbot && <span className="header-notification"><span className="header-notification-text">Kindly restrict the search to keywords or topics.<br/> This is a system-operated chatbot.</span></span>}
    </div>
  );
};

export default ChatbotHeader;
