import { createChatBotMessage } from "react-chatbot-kit";
import CustomUserMessage from "./CustomUserMessage";
import Banner from "./Banner";
import BotAvatar from "./BotAvatar";
import ChatbotHeader from "./ChatbotHeader";
import CustomBotMessage from "./CustomBotMessage";
import InputMessageContainer from "./InputMessageContainer";
import MainOptions from './widgets/MainOptions';
import { imageSrc } from "../../baseUrl";

const config = {
  initialMessages: [
    createChatBotMessage(
      `<span class="initial-MessageChat">Welcome to <img class="ida-logo" src=${imageSrc}/icons/banner-logo-blue.svg alt="ida"/> - Your E-Assistant.</span>`,
      {
        widget: "options",
      }
    ),
  ],
  customComponents:{
    header: (props) => <ChatbotHeader {...props}/>,
    banner: (props)=> <Banner {...props}/>,
    botAvatar: (props) => <BotAvatar {...props} />,
    botChatMessage: (props) => <CustomBotMessage {...props}/>,
    userChatMessage: (props) => <CustomUserMessage {...props} />,
    InputMessageContainer: (props)=> <InputMessageContainer {...props}/>
  },
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <MainOptions {...props} />,
    }
  ],
};

export default config;
