import React, { useEffect, useState } from "react";
import ChatBotMain from "./components/ChatBot/ChatBotMain";
import "./components/Styled/App.scss"
import { getHostName } from "./components/ChatBot/utils";

const App = () => {
  const [showChatbot, setShowChatbot] = useState(true);
  useEffect(()=>{
    //if host name dose not have any of these four host then disable the chatbot
    const host = getHostName()
    visualViewport.addEventListener('resize', () => {
      document.documentElement.style.setProperty('--viewport-height-for-Chatbot', `${visualViewport.height}px`);
    });
    // console.log(host)
    if(host === "linkintime" || host === "tcplindia" || host === "unisec" || host === "skdc-consultants"){
      setShowChatbot(true)
    }else{

    }
  }, [])
  return (
    <div className="App">
      <div className="App-ChatBotContainer">
        { showChatbot && <ChatBotMain/> }
      </div>
    </div>
  );
};

export default App;
