import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import RenderPdf from "./RenderPdf";
import "../../Styled/MessageHandler.scss";
import { imageSrc } from '../../../baseUrl'
import { SendMessageEmailCounter } from "../../apiCall";
import Forms from "../Forms/Forms";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useRef } from "react";


const MessageHandler = ({ addPlaneMessage, data, msg, last, Rated, changeIsRated, handleMainOption }) => {
  const [previewPDF, setPreviewPDF] = useState(false);
  const [botMsg, setBotMsg] = useState();
  const [botmsgArray, setBotMsgArray] = useState([]);
  const [msgState, setMessageState] = useState(1);
  const [preview,setPreview] = useState(false)
  const [download,setDownload] = useState(false)
  const [print,setPrint] = useState(false)
  const [blob, setBlob] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [AllArrayPopUps, setAllArrayPopups] = useState({});
  const [readID, setReadID] = useState();
  const MessageBoxRef = useRef()
  const {untaggedCount, setuntaggedCount, setShowPopup,showPopup, setShowIsBot} = useContext(Context)
  

  useEffect(() => {
    // console.log(data)
    // console.log("Data From Dispatch Handler: =>",data)
    setReadID(uuidv4())

// =========================== Handling Read More With Unique Ident =================================
    let string = msg;
    const uniqueReadMoreIdentifier = "****************************"
    string = string.replaceAll("__Read__more__", uniqueReadMoreIdentifier)
    string = string.replaceAll(`<a href="/" rel="noopener noreferrer" target="_blank">Read more</a>`, uniqueReadMoreIdentifier)
    string = string.replaceAll(`<a href="/">Read more</a>`, uniqueReadMoreIdentifier)
    string = string.replaceAll(/<img [^>]*src="([^"]+)"[^>]*>/gm, uniqueReadMoreIdentifier)
    // const results = string.match(/<img [^>]*src="([^"]+)"[^>]*>/gm)
    // console.log(results)
    
// =========================== Handling the popUp =================================

      const element = MessageBoxRef.current.childNodes[0].childNodes[0].childNodes
      let elements = {}
      let unique = uuidv4()

      if (element && element.length > 1) {
        //loop over all the elements in the answer
        for (let i = 0; i < element.length; i++) {
          const ClassList = element[i].classList ? Array.from(element[i].classList) : [];

          //if an element has class "popup"
          if (ClassList.includes("popup")) {
            if (elements.hasOwnProperty(unique)) {
              elements[unique] = [...elements[unique], element[i]];
            } else {
              elements[unique] = [element[i]];
            }
          } else {
            unique = uuidv4();
          }
        }
        setAllArrayPopups(elements);
        // replace the first popup div to span with id
        Object.entries(elements).forEach(([id, arr]) => {
          string = string.replace(arr[0].outerHTML,`<span class="title-handler"> ${arr[0].innerHTML} <img  id=${id} src=${imageSrc}/icons/popup_logo.svg alt="plus" /></span>`
          );
        });
      }
      


// =========================== Setting Read More Array Messages  =================================
    if(string.split(uniqueReadMoreIdentifier).length > 1){
     const StringArray = string.split(uniqueReadMoreIdentifier);
      setBotMsg(StringArray[0]);
      setBotMsgArray(StringArray);
    }else{
      setBotMsg(string)
    }


// =========================== To Show bot Notification =================================
    if(data.is_untagged){
      if(untaggedCount === 0){
        setuntaggedCount(1)
      }else{
        setShowIsBot(true)
      }
    }

// =========================== Handling the document =================================
    if (data.document !== "null" && data.document) {
     const darr = data.document.split("/")
      setDocumentName(darr[darr.length-1])
      fetch(data.document, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((response) => response.blob())
        .then((blobs) => {
          setBlob(blobs);
        });
    }
  
    
  }, []);


  useEffect(()=>{
    let EmailCounterEvent = MessageBoxRef.current;
    const ClickEventHandler = (event) =>{
    // console.log(event.target)
    // ++++++++++++++++++++++++++++++++++++ start on Click +++++++++++++++++++++++++
    // if(event){
      // console.log(event)
      // const ArrayContent =  AllArrayPopUps[event.target.id].map((e)=>e.outerHTML)
      // let popupClass;
      // document.getElementById(`${event.target.id}`).getBoundingClientRect().top > window.innerHeight / 2 ? popupClass = "top" : popupClass = "bottom"
      // const RederedPopup =  document.getElementById(`${event.target.id+"popup-container"}`)
      //  !RederedPopup && setShowPopup({id: event.target.id+"popup-container",html:ArrayContent.join(" "), pClass: popupClass})

      // setTimeout(()=>{
      //   setShowPopup(ArrayContent.join(" "))
      // }, 100)
    // }
    // ++++++++++++++++++++++++++++++++++++ end on Click +++++++++++++++++++++++++

      const MailType = ["mailto:rnt.helpdesk@linkintime.co.in", "mailto:csg-unit@tcplindia.co.in", "mailto:info@unisec.in", "mailto:info@skdc-consultants.com" ]
      if(MailType.includes(event.target.href)){
        SendMessageEmailCounter(data.typed_msg)
        EmailCounterEvent.removeEventListener("click",ClickEventHandler)
      }
    }

    if (readID) {
      EmailCounterEvent.addEventListener("click", ClickEventHandler);
    }
    
    return () => {
      if (EmailCounterEvent) {
        EmailCounterEvent.removeEventListener("click", ClickEventHandler);
      }
    };
  },[readID])

  useEffect(()=>{
    const container = MessageBoxRef.current.parentNode.parentNode.parentNode  // looking for this div "react-chatbot-kit-chat-bot-message bot-message"
    const MoserOverEvent = (event) =>{
      if(event.target.id && Object.hasOwn(AllArrayPopUps, event.target.id) ){
       let popupClass;
       document.getElementById(`${event.target.id}`).getBoundingClientRect().top > window.innerHeight / 2 ? popupClass = "top" : popupClass = "bottom"
       const ArrayContent =  AllArrayPopUps[event.target.id].map((e)=>e.outerHTML)
        const popupContent = ArrayContent.join(" ");
        
      //  cheking if we have popup with same id already rendered if not the set the state
      const RederedPopup =  document.getElementById(`${event.target.id+"popup-container"}`)
       if(!RederedPopup){
      
       const showPopupObject = {
        id: event.target.id+"popup-container",
        html: popupContent, 
        pClass: popupClass,
        containsLink: popupContent.includes("<a ")
       };
        setShowPopup(showPopupObject)
       }

      // (RederedPopup) && RederedPopup.addEventListener("mouseleave",(e)=>{ setShowPopup(null)})
     } else {
      if(showPopup && !showPopup.containsLink) setShowPopup(null)
     }
   }

   if (Object.keys(AllArrayPopUps).length && container) {
    container.addEventListener("mouseover", MoserOverEvent);
  }
  
  return () => {
    if (container) {
      container.removeEventListener("mouseover", MoserOverEvent);
    }
    
  };
  }, [readID, showPopup])


  const onReadMore = (e) => {
    setMessageState((prevmsg) => ++prevmsg);
    e.preventDefault();
    let msg = "";
    for (let index = 0; index < msgState + 1; index++) {
      msg = `${msg} ${botmsgArray[index]}`;
    }
    setBotMsg(msg);
    setTimeout(()=>{
      document.getElementById(`${readID}`).scrollIntoView({block: "center"})
    },300)
  };

  const onReadLess = (e) => {
    setMessageState((prevmsg) => --prevmsg);
    e.preventDefault();
    let msg = "";
    for (let index = 0; index < msgState - 1; index++) {
      msg = `${msg} ${botmsgArray[index]}`;
    }
    setBotMsg(msg);
  };

  const handleDownload = () => {
    document.getElementById("inputCon").focus()
    setDownload(true)
    setPrint(false)
    setPreview(false)
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${documentName ? documentName : "form"}`);
    // Append to html link element page
    document.body.appendChild(link);
    // // Start download
    link.click();
    // // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const handlePrint = () => {
    document.getElementById("inputCon").focus()
    setPrint(true)
    setDownload(false)
    setPreview(false)
    var file = new Blob([blob], { type: "application/pdf" }); //this make the magic
    var blobURL = URL.createObjectURL(file);
    let iframe = document.createElement("iframe"); //load content in an iframe to print later
    document.body.appendChild(iframe);
    iframe.style.display = "none";
    iframe.src = blobURL;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  };

  const MessageJSX = ()=>{
    return(
      botMsg ? (
        <div  className="MessageHandler-botmessage" >
          {/* <a onclick="emailCounter()" href="/" target="_blank">test</a> */}
          <span  dangerouslySetInnerHTML={{ __html: botMsg }}></span>
          {(botmsgArray.length > 0) &&  (
            <div className="MessageHandler-botmessage-read" id={readID}>
              <span>View</span>
                {(botmsgArray.length > 2 && !(msgState < botmsgArray.length) ) && <span></span>}
              {msgState < botmsgArray.length && (
                <a href="/" onClick={(e) => onReadMore(e)}>
                   <img title="More" src={`${imageSrc}/icons/Arrow.svg`} alt="arrow down"/>
                </a>
              )}
              {msgState > 1 && (
                <a href="/" onClick={(e) => onReadLess(e)}>
                  <img title="Less" src={`${imageSrc}/icons/Arrow.svg`} className="MessageHandler-botmessage-read-up" alt="arrow down"/>
                </a>
              )}
            </div>
          )}
        </div>
      ) : (
        // when it is the last message like "i can help you with" or without more less option
        <div className="MessageHandler-botmessage">
          <div  dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      )
    )
  } 

  const DocumentHandlerJSX = () =>{
    return (
          <div className={`MessageHandler-btns`}>
            <span className={`MessageHandler-btns-name`}>{documentName}</span>
            <div className={`MessageHandler-btns-container`}>
            <div
              className={`MessageHandler-btn ${preview && "MessageHandler-btn-active"}` }
              onClick={() => {setPreviewPDF(true); setPreview(true); setDownload(false); setPrint(false);  document.getElementById("inputCon").focus()}}
            >
              Preview
            </div>
            {previewPDF && (
              <RenderPdf
                pdf={data.document}
                previewPDF={previewPDF}
                setPreviewPDF={setPreviewPDF}
              />
            )}
            <div className={`MessageHandler-btn ${download ? "MessageHandler-btn-active" : ""}` } onClick={handleDownload}>
              Download
            </div>
            <div className={`MessageHandler-btn ${print ? "MessageHandler-btn-active": ""} ` } onClick={handlePrint}>
              Print
            </div>
            </div>
          </div>
    )
  }

  const UrlHandlerJSX = () =>{
    return(  
    <span className="MessageHandler-url">
    <span>URL:</span> <a target="_blank" rel="noreferrer" href={data.answer_url}> {data.answer_url}</a>
    </span>
    )
  }


  return (
    <div className="MessageHandler" id={readID+"-container"} ref={MessageBoxRef}>
      
     {(msg.toLowerCase().includes('please rate your experience') || data.input_required) ?
     
     <Forms {...{msg, addPlaneMessage, Rated, changeIsRated, data, handleMainOption}} input_required={data.input_required}/>
     
   :(<>
           {/* handle message with more less option if not there then show plane msg  */}
          <MessageJSX/>
           {/* if message is not last then handle the document and url */}
          {(data.options && data.document !== "null" && !last)  && <DocumentHandlerJSX/>}
          {(data.answer_url && data.answer_url !== "null" && !last) && <UrlHandlerJSX/>}
          {/*last message will have options for more help and giving ratings, handled by MainOptions Component */}
        </>)}
    </div>
  );
};

export default MessageHandler;
