//Production Server
export const baseUrl = "https://idia-backend.linkintime.co.in";

export const imageSrc = "https://idia.linkintime.co.in";

// export const baseUrl = "http://127.0.0.1:8000";

// Data - Add server
// export const baseUrl = "http://43.204.39.121:9000";

// export const imageSrc = "http://data-add-linkintime-chatbot.s3-website.ap-south-1.amazonaws.com";

// export const imageSrc = "http://www.linkintime.com.s3-website.ap-south-1.amazonaws.com";

// Bluetick Test server
// export const baseUrl = "http://43.204.39.121";

// export const imageSrc = "https://link-intime-test-server.s3.ap-south-1.amazonaws.com";

// "http://3.111.202.173"
// http://127.0.0.1:8000
